import React, { Component, useState } from "react";
import OneConcept from "./assets/one-concept.png";
import NoBullets from "./assets/no-bullets.png";
import ActionItems from "./assets/action-items.png";
import MeSpeaking from "./assets/me-speaking.png";
export default function Micromanager() {
  return (
    <div>
      <article id="918569f2-8a74-4c85-becc-19dbc42e018d" className="page sans">
        <div className="page-body">
          <p id="14e40484-2295-800b-96a0-dafbdaba7914" className="">
            A few weeks ago, a manager in the office approached me for advice.
            "How can I avoid micromanaging my direct reports?" they asked.
            "During your team meetings, you seem to give your workers
            considerable freedom and space—you don't tell them what to do, but
            rather ask guiding questions."
          </p>
          <p id="14e40484-2295-801b-9729-cf5591b062c5" className="">
            I was surprised by this observation since I don't consider myself a
            hands-off manager. In fact, I insist on knowing every detail,
            conducting code reviews, and closely monitoring any delayed tasks.
          </p>
          <p id="14e40484-2295-8077-9628-d1b4818b4dd0" className="">
            After reflection, I realized that my lack of micromanagement is
            something my employees <strong>earn</strong>—not a birthright. They
            must work hard to achieve this trust, and not everyone receives the
            same level of close supervision.
          </p>
          <p id="14e40484-2295-80df-91b5-c2d157bd1e37" className="">
            This realization inspired me to write this article: to highlight how
            you, as workers, can significantly influence whether your manager
            micromanages you.
          </p>
          <h3 id="14e40484-2295-80bd-a0c8-f902efae723b" className="">
            The Key: Get Into Your Manager's Head
          </h3>
          <p id="1fa907ad-db81-4f2b-81ab-4aa5b234ad3c" className="">
            If you're frustrated with micromanagement, you need to understand
            its root cause: Why is your manager involved in every detail and
            constantly asking about progress? The answer lies in understanding
            your manager's perspective and motivations.
          </p>
          <p id="e274dbfd-7380-421f-a691-b65729f69b7e" className="">
            But first, a disclaimer.
          </p>
          <p id="96c00502-6e17-49cc-b348-e2d1b7a32969" className="">
            Let's be clear - some managers will micromanage regardless of what
            you do. This article isn't about those managers. Instead, we'll
            focus on generally competent managers who vary their management
            style: sometimes giving you space, other times hovering like a
            buzzing bee. Maybe they're hands-off with others but watch your work
            closely.
          </p>
          <p id="14e40484-2295-80ed-9c8c-daa1dc800b33" className="">
            Let's explore why this happens and how to handle it.
          </p>
          <h3 id="1edbf9d9-beeb-4bfa-8f1e-4300a4363fad" className="">
            But First, What Do Managers Want?
          </h3>
          <p id="14e40484-2295-8097-90a5-e6aae87e5979" className="">
            Generally speaking, your manager excelled in their previous
            role—that's how they got promoted. In software development, this
            usually means they were outstanding individual contributors who
            consistently delivered high-quality features on time.
          </p>
          <p id="14e40484-2295-80f5-a368-de7eb950f447" className="">
            After promotion, however, they face a paradox: they have more
            influence but less direct control over project pace and quality of
            execution. This shift often leads to frustration.
          </p>
          <p id="14e40484-2295-8009-9912-e63d28d5de3b" className="">
            Instead of making their own estimates, they{" "}
            <span style={{ borderBottom: "0.05em solid" }}>
              have to take your word for it
            </span>
            . Rather than having firsthand knowledge as a lead developer, they{" "}
            <span style={{ borderBottom: "0.05em solid" }}>
              have to retrieve that information directly from you
            </span>
            —constantly and repeatedly.
          </p>
          <p id="14e40484-2295-803c-b077-e253d8d64bda" className="">
            Moreover, your manager faces significant pressure from upper
            management about project progress, deadlines, and business risks.
            Like everyone else, they're being evaluated and want to excel to
            advance their career.
          </p>
          <p id="14e40484-2295-8081-8c64-e220061c074f" className="">
            These factors combine to create the "control freak" micromanager you
            see today. Micromanagement is essentially their attempt to{" "}
            <strong>
              gain some control and predictability over their chaotic work-life
            </strong>
            .
          </p>
          <p id="14e40484-2295-80a9-b867-cfd34d2d6653" className="">
            So what can you do?
          </p>
          <h3 id="14e40484-2295-807e-b467-c79748ce01a3" className="">
            Earn Their Trust
          </h3>
          <p id="15c40484-2295-8073-abde-d52929c92c10" className="">
            The logic is simple: the more your manager trusts you, the more
            control they feel over delegated responsibilities, and the less
            they'll micromanage you.
          </p>
          <p id="14e40484-2295-80ec-8120-dbc467010cde" className="">
            Building your manager's trust is a gradual process. You'll earn it
            step by step, accumulating small wins until they fully trust your
            judgment.
          </p>
          <p id="15c40484-2295-808e-adfe-d496de82e422" className="">
            Start with the basics: complete tasks within estimated timeframes,
            deliver bug-free features, and promptly flag any roadblocks that
            need their intervention. With each success, trust grows—until your
            manager knows instinctively that tasks in your hands will be
            well-handled.
          </p>
          <p id="14e40484-2295-8034-9ed0-d2a3cf30c1fa" className="">
            Beyond excellent performance, focus on improving your upward
            communication. Here's how to effectively share information with your
            manager:
          </p>
          <h3 id="14e40484-2295-808a-ae1d-f5ae5ee9f100" className="">
            <strong>
              Change Your "Verbosity" from "CRITICAL" to "WARN" (Sometimes Even
              "INFO"!)
            </strong>
          </h3>
          <p id="14e40484-2295-8013-a79d-e79302a54c1d" className="">
            Most of us only report major issues to our managers—like missed
            deadlines or production problems—as if we're set to "CRITICAL"
            logging level.
          </p>
          <p id="14e40484-2295-8063-a71f-d22b40326d01" className="">
            Instead, switch to "WARN" level and regularly share updates about
            less urgent matters. Don't wait for scheduled meetings. This
            proactive communication keeps your manager informed and prevents
            surprises. It enables them to adjust plans early when needed.
            Remember: predictability is key!
          </p>
          <p id="14e40484-2295-8015-83eb-ce4fc30e8d22" className="">
            Share updates like "Waiting on DevOps support before proceeding,"
            "Testing is more complex than anticipated—need an extra 2-3 days,"
            or "Feature is in review, expected completion by end of day" with
            your manager<strong> as soon as they happen.</strong>
          </p>
          <p id="14e40484-2295-80ab-b577-d4ada6b6b5c9" className="">
            Remember: "You update me too much on your progress" -{" "}
            <span style={{ borderBottom: "0.05em solid" }}>
              Said no manager, ever.
            </span>
          </p>
          <h3 id="14e40484-2295-8053-b5fd-d0c753ded3b9" className="">
            <strong>Be Proactive in Your 1:1s, Use Them Wisely</strong>
          </h3>
          <p id="14e40484-2295-80cc-9d55-f664658ce962" className="">
            While regular 1:1s with your manager are valuable, they often feel
            more like interrogations than conversations.
          </p>
          <p id="15c40484-2295-80ea-9012-e910e42902a4" className="">
            These meetings typically start with casual "How was your week?"
            before shifting to project status updates and questions about
            delays. This questioning pattern emerges because managers need
            information to report upward.
          </p>
          <p id="15c40484-2295-80f5-8fe9-edd825be14f1" className="">
            If this sounds familiar, you're probably not being{" "}
            <strong>proactive enough in your 1:1s</strong>. Your manager has
            learned to ask about every detail because they aren't getting
            updates any other way!
          </p>
          <p id="14e40484-2295-8074-b89f-f822fcd201d0" className="">
            Take control of these conversations.{" "}
            <strong>Plan and prepare in advance</strong> key points about your
            projects and what you need from your manager to succeed.
          </p>
          <p id="15c40484-2295-8068-85e7-d0d9a3d40592" className="">
            Engage actively by asking questions and highlighting concerns.
            You'll find it more empowering to proactively report progress rather
            than respond to queries. This approach makes meetings more
            productive—and enjoyable—for everyone.
          </p>
          <h3 id="14e40484-2295-8087-997b-dc808c66c11b" className="">
            In Conclusion
          </h3>
          <p id="14e40484-2295-8006-9654-efe6833b4768" className="">
            This article empowers you, the developers, to actively manage your
            relationship with your manager while understanding their
            perspective.
          </p>
          <p id="14e40484-2295-809a-8ece-d83352f94441" className="">
            As you build trust, you'll notice your manager giving you more
            autonomy and space to work independently.
          </p>
        </div>
      </article>
    </div>
  );
}
